import React from "react"
import { Modal } from "@redq/reuse-modal"
import { graphql } from "gatsby"

import AboutPagesContent from "../containers/AboutPagesContent"
import BreadcrumbTreatments from "../containers/BreadcrumbsTreaments/index.js"
import ChangeYourLife from "../containers/ChangeYourLife"
import Faq from "../containers/Faq"
import Layout from "../containers/Layout"
import PageTitle from "../containers/PageTitle"
import SEO from "../components/SEO"
import TreatmentsIntro from "../containers/TreatmentsIntro"
import { ContentWrapper } from "../containers/elegant.style"

const ConditionsTemplate = ({ data, location }) => {
  const {
    slug,
    seoDetails,
    content,
    description,
    faqs,
    mainImage,
    title,
  } = data.contentfulConditionsSkinCondition
  const url = location.href ? location.href : ""

  return (
    <>
      <SEO
        url={url}
        image={`https:${mainImage.fluid.src}`}
        title={`${seoDetails.metaTitle}`}
        shortDesc={`${seoDetails.metaShortDescription}`}
        description={`${seoDetails.metaDescription}`}
      />
      <Layout>
        <Modal />
        <ContentWrapper>
          <BreadcrumbTreatments
            treatmentTitle={title}
            slug={slug}
            treatmentCategory="Conditions"
          />
          <PageTitle title={`${title}`} text={description.description} />
          <TreatmentsIntro
            category="conditions"
            treatmentDescription={description}
            treatmentImage={mainImage}
            title={title}
          />
          <AboutPagesContent content={content} />
          <Faq
            treatmentFaqs={faqs}
            treatmentCategory="conditions"
            treatmentTitle={title}
          />
          <ChangeYourLife
            whiteBG
            title="Ready to start your journey with Elegant?"
            text="We’re here to help you feel your best. Explore our services, get in touch and book your consultation today."
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default ConditionsTemplate

export const conditionsQuery = graphql`
  query conditionsBySlug($slug: String!) {
    contentfulConditionsSkinCondition(slug: { eq: $slug }) {
      title
      slug
      mainImage {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      faqs {
        question {
          question
        }
        answer {
          json
        }
      }
      description {
        description
      }
      content {
        json
      }
      seoDetails {
        metaTitle
        metaShortDescription
        metaDescription
      }
    }
  }
`
