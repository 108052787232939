import Fade from "react-reveal/Fade"
import PropTypes from "prop-types"
import React from "react"
import { BLOCKS } from "@contentful/rich-text-types"
/* eslint-disable react/display-name */
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import ReferAFriendForm from "../ReferAFriendForm"
import Text from "../../components/Text"
import AboutContentWrapper, {
  ButtonGroup,
  ContentSection,
} from "./aboutPagesContent.style"

const AboutContent = ({ content, slug }) => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <img src={node.data.target.fields.file["en-US"].url} alt="blogpost" />
      ),
    },
  }
  return (
    <AboutContentWrapper>
      <Container width="1805px">
        <Fade up delay={100}>
          <ContentSection>
            {documentToReactComponents(content.json, options)}
            {/* {slug === "refer-a-friend" && (
              <>
                <ReferAFriendForm />
                <div className="terms-content">
                  <Text content="Terms and conditions:" className="terms" />
                  <ul>
                    <li>
                      Not to be used in conjunction with any other offers.
                    </li>
                    <li>
                      The referrer will only receive £25 off once the new client
                      has paid for theirtreatment. The new client will receive
                      ££50 off after their first treatment.{" "}
                    </li>
                    <li>
                      Discounts can only be used on treatments over £150 not on
                      treatments below this price or on products.{" "}
                    </li>
                  </ul>
                </div>
              </>
            )} */}
            {slug === "gift-voucher" && (
              <>
                <ButtonGroup>
                  <a
                    href="https://bookings.gettimely.com/elegantbeautyclinic/bb/purchase?giftVoucherId=172213"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button title="Purchase a gift voucher" />
                  </a>
                </ButtonGroup>
                <div className="terms-content">
                  <Text content="Terms and conditions:" className="terms" />
                  <ul>
                    <li>
                      Gift vouchers are valid for 1 year from the date of
                      purchase.
                    </li>
                    <li>Redeemable only against treatments or products.</li>
                    <li>Redeemable once.</li>
                    <li>In-clinic use only.</li>
                    <li>No change given.</li>
                    <li>No refunds.</li>
                    <li>No cash exchanges.</li>
                    <li>Multiple vouchers can be used together.</li>
                    <li>
                      Voucher details must be stated during consultation
                      booking.
                    </li>
                    <li>Voucher must be brought to the appointment</li>
                  </ul>
                </div>
              </>
            )}
          </ContentSection>
        </Fade>
      </Container>
    </AboutContentWrapper>
  )
}

// MainImage style props
AboutContent.propTypes = {
  content: PropTypes.object,
}

// MainImage default style
AboutContent.defaultProps = {
  imageWrapper: {
    display: " block",
  },
}

export default AboutContent
