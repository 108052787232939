import * as Yup from "yup"
import Fade from "react-reveal/Fade"
import PropTypes from "prop-types"
import styled from "styled-components"
import React, { useState } from "react"
import { Field, Form, Formik, useField } from "formik"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import Box from "../../components/Box"
import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import ContactWrapper, {
  CheckBoxes,
  ContactFromWrapper,
  ContentWrapper,
  StyledErrorMessage,
  StyledSelect,
} from "./referAFriendForm.style"

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  )
}

const StyledLabel = styled.label`
  margin-top: 1rem;
  color: red;
`

const ReferAFriendForm = ({ formTitle }) => {
  const [msgSent, setMsgSent] = useState(false)

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const trackButtonEvent = () => {
    trackCustomEvent({
      category: "Refer A Friend",
      action: "submit",
      label: "Refer a friend form",
    })
  }

  return (
    <ContactWrapper>
      <Box>
        <ContactFromWrapper>
          {!msgSent ? (
            <Fade up delay={100}>
              <Heading content="Refer a friend" {...formTitle} />
            </Fade>
          ) : (
            <Fade up delay={100}>
              <Heading
                content="Your form has been submitted succesfully"
                {...formTitle}
              />
            </Fade>
          )}
          <Formik
            initialValues={{
              yourName: "",
              friendsName: "",
              email: "",
              friendsEmail: "",
              phone: "",
              friendsPhone: "",
            }}
            validationSchema={Yup.object({
              yourName: Yup.string().required("Required"),
              friendsName: Yup.string().required("Required"),
              phone: Yup.string().required("Required"),
              friendsPhone: Yup.string().required("Required"),
              email: Yup.string()
                .email("Invalid email addresss")
                .required("Required"),
              friendsEmail: Yup.string()
                .email("Invalid email addresss")
                .required("Required"),
            })}
            onSubmit={(values, actions) => {
              fetch("/", {
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                body: encode({ "form-name": "refer-a-friend", ...values }),
              })
                .then(() => {
                  setMsgSent(true)
                  // alert("Success")
                  actions.resetForm()
                })
                .catch(() => {
                  alert("Error")
                })
                .finally(() => actions.setSubmitting(false))
            }}
          >
            <Form name="refer-a-friend" data-netlify={true}>
              <ContentWrapper>
                <Fade bottom>
                  <MyTextInput
                    label="Your name *"
                    name="yourName"
                    type="text"
                  />
                </Fade>
                <Fade bottom>
                  <MyTextInput
                    label="Friends name *"
                    name="friendsName"
                    type="text"
                  />
                </Fade>
                <Fade bottom>
                  <MyTextInput label="Your Email *" name="email" type="email" />
                </Fade>
                <Fade bottom>
                  <MyTextInput
                    label="Friends Email *"
                    name="friendsEmail"
                    type="email"
                  />
                </Fade>
                <Fade bottom>
                  <MyTextInput
                    label="Phone number *"
                    name="phone"
                    type="text"
                  />
                </Fade>
                <Fade bottom>
                  <MyTextInput
                    label="Friends phone number *"
                    name="friendsPhone"
                    type="text"
                  />
                </Fade>
              </ContentWrapper>
              <Fade bottom>
                <div>
                  <button onClick={trackButtonEvent} type="submit">
                    Submit form
                  </button>
                </div>
              </Fade>
            </Form>
          </Formik>
        </ContactFromWrapper>
      </Box>
    </ContactWrapper>
  )
}

// PricesAndOffers style props
ReferAFriendForm.propTypes = {
  formTitle: PropTypes.object,
}

ReferAFriendForm.defaultProps = {
  // form title default style
  formTitle: {
    textAlign: "center",
    fontSize: ["28px", "40px"],
    fontWeight: "100",
    letterSpacing: "1px",
    mb: "60px",
    color: "#333333",
  },
}

export default ReferAFriendForm
