import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const AboutContentWrapper = styled.section`
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
`

export const ContentSection = styled.div`
  margin: 80px auto 100px auto;
  text-align: left;
  max-width: 858px;
  color: ${themeGet("colors.textColor", "#333333")};
  @media only screen and (max-width: 991px) {
    margin: 40px 0 60px 0;
  }

  h1 {
    font-size: 48px;
    letter-spacing: 0.8px;
    line-height: 55px;
    font-weight: 400;
    margin: 8px 0;
  }
  h2 {
    font-size: 40px;
    letter-spacing: 0.8px;
    line-height: 55px;
    font-weight: 400;
    margin: 8px 0;
  }
  h3 {
    font-size: 32px;
    letter-spacing: 0.64px;
    line-height: 55px;
    font-weight: 400;
    margin: 8px 0;
  }
  h4 {
    font-size: 24px;
    letter-spacing: 0.48px;
    line-height: 55px;
    font-weight: 400;
    margin: 8px 0;
  }
  p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 24px;
  }

  img {
    max-width: 100%;
    margin-bottom: 24px;
  }

  ul {
    margin-bottom: 32px;
  }

  li {
    list-style-type: disc;
    margin-left: 17px;

    p {
      margin-bottom: 0px;
    }
  }

  .terms-content {
    margin-top: 32px;
    li {
      margin-left: 0px;
      margin-bottom: 8px;
      list-style: none;
      font-size: 14px;
    }
  }
  .terms {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
  }

  .priceListButton {
    text-transform: inherit;
    color: ${themeGet("colors.secondaryTextColor", "#fff")};
    border: 1px solid ${themeGet("colors.primaryBotton", "#fff")};
    padding: 4px 50px;
    margin-top: 20px;
    @media only screen and (max-width: 480px) {
      margin-top: 0px;
    }
    &:hover {
      background-color: ${themeGet("colors.primaryBotton", "#297E7F")};
      border: 1px solid ${themeGet("colors.primaryBotton", "#297E7F")};
      color: ${themeGet("colors.white", "#fff")};
    }
  }
`

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  .reusecore__button {
    margin-top: 24px;
    margin-bottom: 32px;
    text-transform: inherit;
    &:first-child {
      color: ${themeGet("colors.white", "#fff")};
      background-color: ${themeGet("colors.secondaryBotton", "#886638")};
      border: 1px solid ${themeGet("colors.secondaryBotton", "#886638")};
      &:hover {
        background-color: #fbfbf9;
        border: 1px solid ${themeGet("colors.secondaryBotton", "#886638")};
        color: ${themeGet("colors.secondaryBotton", "#886638")};
      }
    }
  }
`

export default AboutContentWrapper
